'use client';
import React, { FC } from 'react';

import AddFavorite from 'components/Data/AddFavorite';
import useIsFavorite from 'hooks/useIsFavorite';

interface Props {
  title: string;
  slug?: string;
  contentArea: string;
  resourceType: string;
  id: string;
  isFavorite?: boolean;
  transparent?: boolean;
  iconClassName?: string;
  hiddenIcon?: boolean;
  programId?: string;
  iconHeight?: string;
  iconWidth?: string;
  additionalText?: string;
  buttonHeight?: number;
  SaveEle?: JSX.Element;
  SavedEle?: JSX.Element;
  showIcon: boolean;
  showBackground?: boolean;
}

const AddFavoriteWrapper: FC<Props> = (props) => {
  const isFavorite = useIsFavorite({
    resourceType: props.resourceType,
    id: props.id,
  });

  return <AddFavorite {...props} isFavorite={isFavorite} />;
};

export default AddFavoriteWrapper;
