import React from 'react';
import { getTimes } from 'suncalc';
import { format } from 'date-fns';

export function getCurrentPosition(options = {}) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

// source: https://www.google.com/search?q=celcius+to+fahrenheit&oq=celcius+to+fah&aqs=chrome.0.69i59j0j69i57j0l3.3138j0j9&sourceid=chrome&ie=UTF-8
export function CtoF(temp) {
  return Math.round(temp * (9 / 5) + 32);
}

// source: https://stackoverflow.com/questions/7490660/converting-wind-direction-in-angles-to-text-words/7490772#7490772
export function degToCompass(num) {
  var val = Math.floor(num / 22.5 + 0.5);
  var arr = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW'
  ];
  return arr[val % 16];
}

// source: https://www.google.com/search?q=meters+per+second+to+miles+per+hour&oq=meters+per+second+to+miles+per+hour&aqs=chrome..69i57j6j0l4.5259j0j4&sourceid=chrome&ie=UTF-8
export function mpsToMph(num) {
  return Math.round(num * 2.237);
}

// source: https://www.google.com/search?ei=HyMBXcUv0LC2BY_okfAL&q=torr+to+inhg&oq=torr+to+inhg&gs_l=psy-ab.3..0i20i263j0l2j0i22i30l7.3328.5353..5665...1.0..0.147.583.7j1......0....1..gws-wiz.......0i71j0i67.N0l305HdQhU
export function torrToInhg(num) {
  let inHg = (num / 25.4).toFixed(2);
  return isNaN(inHg) ? '-' : inHg;
}

// Get the previous index OR min
export function prevIndex(i) {
  return i > 1 ? i - 1 : 1;
}

// Get the next index OR max
export function nextIndex(i, count) {
  return i < count ? i + 1 : count;
}

export function collectionLinkData(collection) {
  return collection?.title && collection?.canonicalSlug
    ? {
      tagName: collection.title,
      to: `${collection.canonicalSlug}`,
      href: '/[...slug]'
    }
    : null;
}

// Formats an ugly time (in seconds) to a nice readable format
// e.g. 125 > 2:05, or 4226 > 1:10:26
export function secondsToHms(timeInSeconds) {
  timeInSeconds = Math.round(timeInSeconds);

  var formattedTime = '';
  var formattedMinutes = '';
  var formattedSeconds = '';
  var hours = Math.floor(timeInSeconds / 3600);
  var minutes = Math.floor(timeInSeconds / 60 - hours * 60);
  var seconds = timeInSeconds - minutes * 60 - hours * 3600;

  if (hours !== 0) {
    formattedTime = hours + 'hr ';

    if (minutes < 10) {
      formattedMinutes = '0' + minutes;
    } else {
      formattedMinutes = minutes.toString();
    }
  } else {
    formattedMinutes = minutes.toString();
  }

  if (seconds < 10) {
    formattedSeconds = '0' + seconds;
  } else {
    formattedSeconds = seconds.toString();
  }

  formattedTime =
    formattedTime + formattedMinutes + 'min ' + formattedSeconds + 'sec';

  return formattedTime;
}

// Checks hrefType and return next/link path

export function hrefType(item) {
  let link;
  switch (item.hrefType) {
    case 'internalLink':
      link = `/${item.href}`;
      break;
    case 'collection':
      link = `/[...slug]`;
      break;
    case 'schedule':
      link = `/schedule`;
      break;
    case 'externalLink':
      link = `${item.href}`;
      break;
    default:
      console.error(
        item, 'link is not a type of internal link, collection link or external link.  Probably a typo.'
      );
  }
  return link;
}

// Checks if hrefType is not externalLink
export function hrefTypeAs(item) {
  return item.hrefType === 'externalLink' ? null : `/${item.href}`;
}

// Checks potlatch alert control for resourceType
export function showInfoAlert(alerts, resourceType) {
  return alerts?.info?.alert &&
    alerts?.info?.show_on?.indexOf(resourceType) > -1
    ? true
    : false;
}

export const audioDownloadPrefix = (playFilePath) => {
  return playFilePath.replace(/%user_agent/, 'web');
};

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function protocol() {
  return process.env.SCHEDULER_PROTOCOL || 'https';
}

// sorts author by numeric order
export function sortByOrder(authors) {
  if (authors) {
    authors.sort((a, b) => {
      return a.order - b.order;
    })
  }
  return authors
}

export function parseEmbeddedAssets(embeddedAssets) {
  try {
    if (typeof embeddedAssets === 'object') {
      for (let [key, value] of Object.entries(embeddedAssets)) {
        if (key !== '__typename') {
          embeddedAssets[key] = JSON.parse(value);
        }
      }
    }
    return embeddedAssets;
  } catch (e) {
    console.error(e);
  }
}

export function parseGraphqlResponse(data) {
  if (typeof data === 'object') {
    for (let key in data) {
      if (key === 'embeddedAssets') {
        try {
          parseEmbeddedAssets(data[key]);
        } catch (e) {
          console.error(e);
        }
      } else if (typeof data[key] === 'object' && data[key] != null) {
        parseGraphqlResponse(data[key]);
      } 
    }
  }  
  return data;
}

export function youtubeParser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}

export function getVideoEmbedUrl(url) {
  let playerEmbedUrl;
  const uri = new URL (url)
  switch(uri.hostname) {
    case 'www.facebook.com':
      playerEmbedUrl = `https://www.facebook.com/plugins/video.php?href=${url}&show_text=0`
        break;
    case 'player.vimeo.com':
    case 'mpr.apmcdn.org':
      playerEmbedUrl = url
        break;
    case 'www.youtube.com':
      playerEmbedUrl = `https://www.youtube.com/embed/${youtubeParser(url)}`
        break;
    default:
      console.error(playerEmbedUrl, 'source is not a valid video url')
  }
  return playerEmbedUrl;
}

export function getVideoPlayer(video, isAmp, title) {
  let playerEmbed;
  const uri = new URL (video.url)

  if (isAmp) {
    switch(uri.hostname) {
      case 'www.facebook.com':
        playerEmbed = <amp-facebook title={title} data-href={video.url} width="420" height="560" layout="responsive" data-embed-as="video"></amp-facebook>
          break;
      case 'player.vimeo.com':
      case 'mpr.apmcdn.org':
        playerEmbed = <amp-video title={title} id={video.credit.name} width="500" height="360" autoPlay="autoplay" muted="muted" loop="loop"><source src={video.url} type="video/mp4"/></amp-video>
          break;
      case 'www.youtube.com':
        playerEmbed =  <amp-youtube title={title} data-videoid={youtubeParser(video.url)} width="500" height="360"></amp-youtube>
          break;
      default:
        console.error(playerEmbed, 'source is not a valid video url')
    }
  } else {
    switch(uri.hostname) {
      case 'www.facebook.com':
        playerEmbed = <div className="iframe-container video facebook"><iframe title={title} src={`https://www.facebook.com/plugins/video.php?href=${video.url}&show_text=0`} scrolling="no" frameBorder="0" allowFullScreen></iframe></div>
          break;
      case 'player.vimeo.com':
      case 'mpr.apmcdn.org':
        playerEmbed = <video id={video.credit.name} autoPlay={true} muted={true} loop={true}><source src={video.url} type="video/mp4"/>Your browser does not support HTML5 video.</video>
          break;
      case 'www.youtube.com':
        playerEmbed =  <div className="iframe-container video youtube"><iframe title={title} src={`https://www.youtube.com/embed/${youtubeParser(video.url)}`} allowFullScreen></iframe></div>
          break;
      default:
        console.error(playerEmbed, 'source is not a valid video url')
    }
  }
  return playerEmbed
}

export function winddir(wd){

  let dir;
  if (wd < 0 || wd > 360) {
    return null;
  }

  if (wd >= 0 && wd <= 11.25) {
    dir = "N";
  }

  if (wd > 348.75 && wd <= 360) {
    dir = "N";
  }

  if (wd > 11.25 && wd <= 33.75) {
    dir = "NNE";
  }

  if (wd > 33.75 && wd <= 56.25) {
    dir = "NE";
  }

  if (wd > 56.25 && wd <= 78.75) {
    dir = "ENE";
  }

  if (wd > 78.75 && wd <= 101.25) {
    dir = "E";
  }

  if (wd > 101.25 && wd <= 123.75) {
    dir = "ESE";
  }

  if (wd > 123.75 && wd <= 146.25) {
    dir = "SE";
  }

  if (wd > 146.25 && wd <= 168.75) {
    dir = "SSE";
  }

  if (wd > 168.75 && wd <= 191.25) {
    dir = "S";
  }

  if (wd > 191.25 && wd <= 213.75) {
    dir = "SSW";
  }

  if (wd > 213.75 && wd <= 236.25) {
    dir = "SW";
  }

  if (wd > 236.25 && wd <= 258.75) {
    dir = "WSW";
  }

  if (wd > 258.75 && wd <= 281.25) {
    dir = "W";
  }

  if (wd > 281.25 && wd <= 303.75) {
    dir = "WNW";
  }

  if (wd > 303.75 && wd <= 326.25) {
    dir = "NW";
  }

  if (wd > 326.25 && wd <= 348.75) {
    dir = "NNW";
  }

  return dir;
}

export function removeZeroFromDuration(duration) {
  return duration
    .replace(/^0+/, '')
    .replace(/^0+/, '')
    .replace(/^:+/, '')
    .replace(/^0+/, '');
}

function formatTime(dateString) {
  const date = new Date(dateString);
  const formattedTime = format(date, 'h:mma');

  return formattedTime;
}

export function getSunriseSunset(lat, lon) {
  const date = new Date();
  const times = getTimes(date, lat, lon);
  const sunrise = formatTime(times.sunrise)
  const sunset = formatTime(times.sunset)

  return { sunrise, sunset };
}

export function timeAgo(date) {
  const now = new Date();
  const inputDate = new Date(date);

  const diffInDays = Math.floor(
    (now.getTime() - inputDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  if (diffInDays === 0) {
    let hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const ampm = hours >= 12 ? ' PM' : ' AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime =
      hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ampm;
    return strTime;
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else {
    return diffInDays + ' days ago';
  }
}

export function getTime(time) {
  let hours = time.getHours();
  const minutes = time.getMinutes();
  const amOrPm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const strTime =
    hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + amOrPm;
  return strTime;
}