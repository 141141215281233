'use client';
import { useSession } from 'next-auth/react';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import Modal from 'components/Modal';
import Link from 'next/link';
import sty from './styles.module.css';
import { Header } from 'components/Modal';
import { FavoritesContext } from 'context/FavoritesContext';
import IconSave from 'components/Icons/IconSave';

interface Props {
  title: string;
  slug?: string;
  contentArea: string;
  resourceType: string;
  id: string;
  isFavorite?: boolean;
  transparent?: boolean;
  iconClassName?: string;
  programId?: string;
  iconHeight?: string;
  iconWidth?: string;
  additionalText?: string;
  buttonHeight?: number;
  SaveEle?: JSX.Element;
  SavedEle?: JSX.Element;
  showIcon: boolean;
  showBackground?: boolean;
}

const AddFavorite: FunctionComponent<Props> = ({
  title,
  contentArea,
  resourceType,
  slug,
  id,
  isFavorite,
  transparent,
  iconClassName,
  programId,
  iconHeight,
  iconWidth,
  additionalText,
  buttonHeight,
  SaveEle,
  SavedEle,
  showIcon = true,
  showBackground,
}) => {
  const sessionData = useSession();
  const status = sessionData.status;
  const [isOn, setIsOn] = useState(isFavorite);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showStoriesListModal, setShowStoriesListModal] =
    useState<boolean>(false);
  const context = useContext(FavoritesContext);
  useEffect(() => {
    setIsOn(isFavorite);
  }, [isFavorite]);

  const handleClick = async () => {
    if (status === 'authenticated') {
      const method = isOn ? 'DELETE' : 'POST';
      const responsePromise = fetch('/api/data/favorite', {
        method,
        credentials: 'same-origin',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title,
          contentArea,
          resourceType,
          slug,
          cmsId: id,
          programId,
        }),
      });
      setIsOn(!isOn);
      if (resourceType === 'story') {
        if (!isOn && (!context.favorites || context.favorites.size === 0)) {
          setShowStoriesListModal(true);
        }
        // setIsOn(!isOn);
        await responsePromise;
        context.fetchFavorites();
      }
    }
    if (status !== 'authenticated') {
      setShowLoginModal(true);
    }
  };

  const loginBtnLinks = [
    {
      text: 'Log in',
      href: '/auth/signin',
      className: 'btn btn-primary',
    },
    {
      text: 'Create a free Account',
      href: '/auth/new-user',
      className: 'btn btn-primary',
    },
  ];

  return (
    <>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowLoginModal(false)}
        show={showLoginModal}
      >
        <div className={sty.modal}>
          <Header
            title="Go Deeper."
            className={sty.modal_header}
            onClose={() => setShowLoginModal(false)}
          />
          <p>Create an account or log in to save stories.</p>
          <div className={sty.modal_buttons}>
            {loginBtnLinks.map((link, idx) => {
              return (
                <Link
                  href={link.href}
                  key={idx}
                  prefetch={false}
                  passHref
                  className={`${link.className} btn`}
                  onClick={() => setShowLoginModal(false)}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowStoriesListModal(false)}
        show={showStoriesListModal}
      >
        <div className={sty.modal}>
          <Header
            title="Like this?"
            className={sty.modal_header}
            onClose={() => setShowStoriesListModal(false)}
          />
          <p>
            Thanks for liking this story! We have added it to a list of your
            favorite stories.
          </p>
          <div className={sty.modal_buttons}>
            <Link
              href="/for-you/stories"
              prefetch={false}
              passHref
              className="btn"
              onClick={() => setShowStoriesListModal(false)}
            >
              Show me!
            </Link>
          </div>
        </div>
      </Modal>
      <IconSave
        className={iconClassName}
        on={isOn}
        transparent={transparent}
        onClick={handleClick}
        height={buttonHeight ?? 25}
        iconHeight={iconHeight}
        iconWidth={iconWidth}
        additionalText={additionalText}
        resourceType={resourceType}
        SaveEle={SaveEle}
        SavedEle={SavedEle}
        showIcon={showIcon}
        isFavorite={isFavorite}
        showBackground={showBackground}
      />
    </>
  );
};

export default AddFavorite;
