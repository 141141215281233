import { FC } from 'react';
import IconBookmark from './IconBookmark';

interface Props {
  className?: string;
  colorOn?: string;
  color?: string;
  height?: number;
  isDownVote?: boolean;
  on?: boolean;
  dark?: boolean;
  transparent?: boolean;
  iconHeight?: string;
  iconWidth?: string;
  additionalText?: string;
  onClick?: () => void;
  resourceType?: string;
  SaveEle?: JSX.Element;
  SavedEle?: JSX.Element;
  showIcon: boolean;
  isFavorite?: boolean;
  showBackground?: boolean;
}

const IconSave: FC<Props> = ({
  className = '',
  colorOn = 'var(--color-green)',
  height,
  isDownVote,
  on,
  dark,
  transparent,
  iconHeight,
  iconWidth,
  additionalText = '',
  onClick,
  resourceType = 'song',
  SaveEle = <b>Save</b>,
  SavedEle = <b>Saved</b>,
  showIcon = true,
  isFavorite,
  showBackground = true,
}) => {
  const statusColorBase = transparent ? 'none' : 'var(--color-white)';
  const saveorsaved = on ? SavedEle : SaveEle;
  const statusColor = on ? colorOn : statusColorBase;

  const btnStyles = {};
  const label = isDownVote
    ? `Saved ${resourceType} (click to undo saving)`
    : `Save ${resourceType}`;

  return (
    <button
      aria-label={label}
      // className={`${className} ${sty.reviewButton}`}
      className={`btn-save ${on ? 'favorite' : ''} ${
        showBackground ? 'btn-round' : ''
      }`}
      style={{ ...btnStyles }}
      title={label}
      onClick={onClick}
    >
      {saveorsaved}
      {showIcon && (
        <span className={`${showBackground ? 'btn-round-inner' : ''}`}>
          <IconBookmark
            color={dark ? 'var(--color-white)' : 'var(--color-mpr-dark-gray)'}
          />
        </span>
      )}
    </button>
  );
};

export default IconSave;
