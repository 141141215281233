import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import isFavorite from 'utils/isFavorite';

interface Props {
  resourceType: string;
  id: string;
}

const useIsFavorite = ({ resourceType, id }: Props) => {
  const sessionData = useSession();
  const session = sessionData.data;
  const [isFav, setIsFav] = useState<boolean | undefined>();

  async function handleIsFav() {
    setIsFav(await isFavorite({ resourceType, id }));
  }

  useEffect(() => {
    if (session) {
      handleIsFav();
    }
  }, [session, resourceType, id]);

  return isFav;
};

export default useIsFavorite;
