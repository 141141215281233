// TODO figure out a way to make this work with cjs  and es6
exports.linkByTypeAs = (item) => {
  let link;
  switch (item.resourceType) {
    case 'link':
      link = item.destination || item.canonicalUrl;
      break;
    case 'collection':
      link = `/${item.canonicalSlug}`;
      break;
    case 'page':
      link = `/${item.canonicalSlug}`;
      break;
    case 'profile':
      link = `/people/${item.canonicalSlug}`;
      break;
    case 'story':
      link = `/story/${item.canonicalSlug}`;
      break;
    case 'episode':
      link = `/episode/${item.canonicalSlug}`;
      break;
    case 'event':
      link = `/event/${item.canonicalSlug}`;
      break;
    case 'live':
      link = `${item.canonicalSlug}`;
      break;

    default:
      link = `/${item.canonicalSlug}`;
  }
  return link;
};

// Returns either external link or resourceType/slug
exports.linkByTypeHref = (item) => {
  let href;
  switch (item.resourceType) {
    case 'link':
      href = item.destination;
      break;
    case 'collection':
      href = `/[...slug]`;
      break;
    case 'page':
      href = `/[...slug]`;
      break;
    case 'schedule':
      href = `/schedule/[day]`;
      break;
    case 'profile':
      href = `/people/[...slug]`;
      break;
    case 'story':
      href = `/story/[...slug]`;
      break;
    case 'episode':
      href = `/episode/[...slug]`;
      break;
    case 'live':
      href = `[...slug]`;
      break;
    default:
      href = `/${item.canonicalSlug}`;
      break;
  }
  return href;
};

exports.analyzeUrl = (url) => {
  const regex = /^https:\/\/www.mprnews.org(\/story|\/people|\/episode|\/schedule)?(\/.+)?/i;
  const match = regex.exec(url);
  if (match) {
    const [, specialType, slug] = match;
    let href, as;
    if (specialType && slug) {
      href = specialType === '/schedule' ? `${specialType}/[day]` : `${specialType}/[...slug]`;
      as = `${specialType}${slug}`;
    } else if (specialType) {
      href = `${specialType}`;
      as = `${specialType}`;
    } else if (slug) {
      href = '/[...slug]';
      as = slug;
    } else {
      href = '/';
      as = '/';
    }

    return {
      isInternal: true,
      href: href,
      as: as
    }
  }

  return {
    isInternal: false,
    href: url
  }
};

exports.toSentence = (contributors) => {
  let str, lastItem, athrs;
  switch (contributors.length) {
    case 0:
      str = '';
      break;
    case 1:
      str = contributors[0].profile.title;
      break;
    case 2:
      str = contributors.map((itm) => itm.profile.title).join(' and ');
      break;
    default:
      lastItem = contributors.pop();
      athrs = contributors.map((itm) => itm.profile.title).join(', ');
      str = `${athrs}, and ${lastItem.profile.title}`;
      break;
  }
  return str;
};

