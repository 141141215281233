import React, { FC } from 'react';

interface Props {
  width?: string;
  color?: string;
  decorative?: boolean;
}

const IconBookmark: FC<Props> = ({
  width = '1rem',
  color = 'var(--color-mpr-dark-gray)',
  decorative = true,
}) => {
  return (
    <svg
      width={width}
      stroke={color}
      strokeWidth="1.75pt"
      fill="none"
      viewBox="0 0 15.6 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.8,15.6l-6.8,2.9V2.2c0-.7.5-1.2,1.2-1.2h11.1c.7,0,1.3.5,1.3,1.2v16.3l-6.8-2.9Z" />
      {!decorative && <title>Bookmark</title>}
    </svg>
  );
};

export default IconBookmark;
