import React, { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconPauseRedBtn: FC<Props> = ({ height = '13', width = '12' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="4.43902" height="13" rx="2.21951" fill="#EB5C40" />
      <rect
        x="7.29236"
        width="4.43902"
        height="13"
        rx="2.21951"
        fill="#EB5C40"
      />
    </svg>
  );
};

export default IconPauseRedBtn;
