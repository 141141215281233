interface FavoriteProps {
  resourceType: string;
  id: string;
}

async function isFavorite({ resourceType, id }: FavoriteProps) {
  if (!id) {
    return false;
  }
  const response = await fetch(`/api/data/favorite/${resourceType}/${id}`);
  const data = await response.json();

  return data.data && data.data.length > 0;
}

export default isFavorite;
